<template>
        <el-dialog title="Produse" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Nume' prop="Nume">
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Tip produs in deviz' prop="TipProdusInDeviz">
                        <el-select class='full-width' v-model='selectedObject.TipProdusInDeviz'>
                            <el-option label='mecanica' value='mecanica'></el-option>
                            <el-option label='roti' value='roti'></el-option>
                            <el-option label='produse roti' value='produse_roti'></el-option>
                            <el-option label='servicii roti' value='servicii_roti'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8' v-if="selectedObject.TipProdusInDeviz!='roti'">
                    <el-form-item label='Pret' prop="Pret" >
                        <el-input-number class='full-width' v-model='selectedObject.Pret' :precision='2' :max='999999'></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :md='8'>
                    <el-form-item label='Unitate de masura' prop="UnitateMasura">
                        <el-input  class='full-width' v-model='selectedObject.UnitateMasura' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Tip produs in oferta' prop="TipProdusInOferta">
                        <el-select class='full-width' v-model='selectedObject.TipProdusInOferta' >
                            <el-option label='piesa' value='piesa'></el-option>
                            <el-option label='manopera' value='manopera'></el-option>
                            <el-option label='kit' value='kit'></el-option>                            
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Tip produs' prop="TipProdus">
                        <el-select class='full-width' v-model='selectedObject.TipProdus' >
                            <el-option label='normal' value='normal'></el-option>
                            <el-option label='servicii rapide' value='servicii_rapide'></el-option>
                            <el-option label='ultra rapide' value='ultra_rapide'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>     
                <el-col :md='8'>
                    <el-form-item v-if="selectedObject.TipProdus === 'servicii_rapide'" label='Tip produs rapid' prop="IdTipProdusRapid">
                        <el-select class='full-width' v-model='selectedObject.IdTipProdusRapid'>
                            <el-option v-for="item in Info.tipuri_produse_rapide" :key="item.Id" :label='item.Nume' :value="item.Id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>    

                <!-- <el-col :md="8">
                    <el-form-item label="Cod">
                        <el-input class="full-width" v-model="selectedObject.Cod" @change="verificaCod"></el-input>
                    </el-form-item>
                </el-col>       -->

                 <!-- zona anvelope -->
                <el-col :span='24' v-if="selectedObject.TipProdusInDeviz=='roti'" >
                    <p>Preturi pe categorii</p>
                    <table class='table'>
                        <tr>
                            <th>Categorie roti</th>
                            <th style='text-align: right'>Pret</th>
                        </tr>
                        <tr v-for='(cr,idx) in Info.categorii_roti' :key="'cr-'+idx">
                            <td>{{cr.Nume}}</td>
                            <td align='right'>
                                <el-input-number size='mini' v-model="cr.Pret" :min="0" :max="9999" :precision="2"></el-input-number>
                            </td>
                        </tr>
                    </table>
                </el-col>
                <!-- end -->          
            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Produse_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {
                    Nume: '' , TipProdusInDeviz: '' , Pret: '' , UnitateMasura: 'buc' , 
                    TipProdusInOferta: '' , TipProdus: '' , DurataManopera: '', IdTipProdusRapid:'',Cod:'' 
                },
                selectedObject: {},
                Info:{ categorii_roti: [], tipuri_produse_rapide:[] },                                    
                rules: {
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipProdusInDeviz: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Pret: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    UnitateMasura: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipProdusInOferta: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipProdus: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DurataManopera: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdTipProdusRapid: [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                camp_roti: false,            
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.camp_roti = false;
                this.showPopup = true;
                if( id == null )
                {
                    this.mode = "add";

                    var            result   = await this.post("produse/get_preturi", { id: -1 } );
                    this.Info.categorii_roti= result.categorii_roti;
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("produse/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;

                    var            result   = await this.post("produse/get_preturi", { id: id } );
                    this.Info.categorii_roti= result.categorii_roti;
                }
            },
            async get_info(){
                var response                    = await this.post("produse/get_info_for_dialog" );
                this.Info.tipuri_produse_rapide = response.tipuri_produse_rapide
            },
            
            save: async function() {                
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {                        
                        await this.post("produse/save", { mode: this.mode, object: this.selectedObject, preturi_categorii_roti:  this.Info.categorii_roti } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
            },
            async verificaCod(value){
                var id = -1

                if(this.mode == 'edit')
                    id = this.selectedObject.Id

                var response = await this.post("produse/verifica_cod",{mode:this.mode,cod:value,id:id})
                // this.$message({type:'info',message:response.check})
                
            }
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>