<template>
        <base-page>
        <titlu-pagina Titlu="Produse" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-produs')"/>
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Tip produs in deviz' >
                                <el-select class='full-width' v-model='Filters.TipProdusInDeviz' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='mecanica' value='mecanica'></el-option>
                                    <el-option label='roti' value='roti'></el-option>
                                    <el-option label='produse roti' value='produse_roti'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Unitate de masura' >
                                <el-input v-model='Filters.UnitateMasura' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Tip produs in oferta' >
                                <el-select class='full-width' v-model='Filters.TipProdusInOferta' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='produs' value='produs'></el-option>
                                    <el-option label='manopera' value='manopera'></el-option>
                                    <el-option label='kit' value='kit'></el-option>                                    
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Tip produs' >
                                <el-select class='full-width' v-model='Filters.TipProdus' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='normal' value='normal'></el-option>
                                    <el-option label='servicii rapide' value='servicii_rapide'></el-option>
                                    <el-option label='ultra rapide' value='ultra_rapide'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col :md='4'>
                            <el-form-item label='Durata manopera' >
                                <el-input v-model='Filters.DurataManopera' />
                            </el-form-item>
                        </el-col>                         -->
                        <el-col :md='24' >
                            <el-button type='primary' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>                            
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='TipProdusInDeviz' label='Tip produs in deviz'></el-table-column>
            <el-table-column prop='Pret' label='Pret'></el-table-column>
            <el-table-column prop='UnitateMasura' label='UM'></el-table-column>
            <el-table-column prop='TipProdusInOferta' label='Tip produs in oferta'></el-table-column>
            <el-table-column prop='TipProdus' label='Tip produs'></el-table-column>
            <el-table-column prop='Cod' label='Cod'></el-table-column>
            <!-- <el-table-column prop='DurataManopera' label='Durata manopera'></el-table-column> -->
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-produs')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="$has_right('stergere-produs')" content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
        </el-table-column>
        </el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Produse-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Produse_dialog from '@/pages/produse/Produse_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "produse",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Produse-dialog': Produse_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                            },
            Filters: {
                Nume: '' , TipProdusInDeviz: '-1' , UnitateMasura: '' , TipProdusInOferta: '-1' , TipProdus: '-1' , DurataManopera: '' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
             if (!this.$has_right('vizualizare-produse')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("produse/get_info" );
                        this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("produse/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('produse');
        },
        reset(){
            this.Filters = {
                Nume: '' , TipProdusInDeviz: '-1' , UnitateMasura: '' , TipProdusInOferta: '-1' , TipProdus: '-1' , DurataManopera: '' ,             };
            this.refresh_info();
        },
    
        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("produse/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
